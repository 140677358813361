jQuery(document).ready(function($) {

	const $mobileNav = $('#mobile-nav');
	const $mobileNavTabs = $('.mobile-nav__tabs');
	const $body = $('body');

    $('.mobile-nav-1', $mobileNav).addClass('--depth-1');
    $('.mobile-nav-1 > li > .sub-menu', $mobileNav).addClass('--depth-2');
    $('.mobile-nav-1 > li > .sub-menu > li > .sub-menu', $mobileNav).addClass('--depth-3');

	// Submenu Change
	function mobileNavSubmenuChange($newSubmenu, removeCurrentActive = false) {
		if(removeCurrentActive === true) {
			$('.sub-menu.--current', $mobileNav).removeClass('--active');
		}
		$('.sub-menu.--current', $mobileNav).removeClass('--current');
		$newSubmenu.next().addClass('--active').addClass('--current');
		$mobileNav.addClass('--subnav-active');
		mobileNavBackText();	
		mobileNavTabMenu();
	}

	// Function to get the back text set for the current sub-menu
	function mobileNavBackText() {
		let backText = 'Back';
		let $currentSubmenu = $('.sub-menu.--current');
		if($currentSubmenu.data('backtext')) {
			backText = $currentSubmenu.data('backtext');
		}

		$('.mobile-nav__back').text(backText);
	}

	// Function to create tab menu
    function mobileNavTabMenu() {
		let $currentSubmenu = $('.sub-menu.--current');

		// If 3rd level, create tab menu content and show
    	if($currentSubmenu.hasClass('--depth-3')) {
    		let $parentSubmenu = $currentSubmenu.parents('.sub-menu.--depth-2');
	    	let tabHTML = '';
	    	let tabCount = 0;
	    	let tabClass = '';

	    	$('> li > a', $parentSubmenu).each(function() {
	    		tabCount++;

	    		// If no children, skip
	    		if(!$(this).parent('li').hasClass('menu-item-has-children')) {
	    			return true;
	    		}

	    		if($(this).next('.sub-menu.--active.--current').length) {
	    			tabClass = ' class="--active"';
	    		}

	    		tabHTML = tabHTML + '<button ' + tabClass + ' data-tabcount="' + tabCount + '">' + $(this).text() + '</button>';
	    		tabClass = '';
	    	});

    		$mobileNavTabs.html(tabHTML).addClass('--active');

    		let tabHeight = $mobileNavTabs.outerHeight();
    		$('.sub-menu.--depth-3.--active.--current').css('padding-top', tabHeight + 'px');

    	} else { // If not 3rd level, reset and hide tabs
			$mobileNavTabs.html('').removeClass('--active');
		}
    }

    // On tab menu click change subnav
    $mobileNavTabs.on('click', 'button', function() {
    	let id = $(this).data('tabcount');
    	let $parent = $('.sub-menu.--active.--current').closest('.sub-menu.--depth-2');

    	mobileNavSubmenuChange($('> li:nth-child(' + id + ') > a', $parent), true);
    });

	// Add parent menu item to sub-menus
    $('.menu-item-has-children', $mobileNav).each(function() {
    	let url = $('> a', $(this)).attr('href');
    	let text = $('> a', $(this)).text();

    	$('> .sub-menu > li > .sub-menu', $(this)).attr('data-backText', text);
    	$('> .sub-menu', $(this)).prepend('<li class="menu-item"><a href="' + url + '">View ' + text + '</a></li>');
    });

    // Close sub-menu
	$('.mobile-nav__back').click(function() {
		let $currentSubmenu = $('.sub-menu.--current', $mobileNav);
		$currentSubmenu.removeClass('--active --current');
		let $nextSubmenu = $currentSubmenu.closest('.--active').addClass('--current');
		mobileNavBackText();
		mobileNavTabMenu();

		if(!$nextSubmenu.length) {
			$mobileNav.removeClass('--subnav-active');
		}

	});
    
    // Open sub-menu
	$('#mobile-nav .menu-item-has-children > a').click(function(e) {
		e.preventDefault();	
		mobileNavSubmenuChange($(this));
	});

	$('.mobile-nav__close').click(function() {
		$body.removeClass('--mobile-nav-active');
		$mobileNav.removeClass('--subnav-active');
		$('.sub-menu.--current').removeClass('--current');
		$('.sub-menu.--active').removeClass('--active');
		$mobileNavTabs.html('').removeClass('--active');
	});

	$('#mobile-nav-button').click(function() {
		$body.addClass('--mobile-nav-active');
	});
 
});
