jQuery(document).ready(function($) {
    
    if($(window).width() < 768) {
    	$('#footer-nav .menu-item-has-children > a').click(function(e) {
			e.preventDefault();
			$(this).toggleClass('--active');
			$(this).next().stop().slideToggle();
		});
    }

    $('#footer-nav .menu-item-has-children').each(function() {
    	let url = $('> a', $(this)).attr('href');
    	$('.sub-menu', $(this)).prepend('<li class="d-lg-none"><a href="' + url + '">View all</a></li>')
    });

    let noChildrenElements = '';
    $('#footer-nav > ul.footer-nav-main > .menu-item:not(.menu-item-has-children)').each(function() {
    	noChildrenElements = noChildrenElements + '<li>' + $(this).html() + '</li>';
    	$(this).remove();
    });

    if(noChildrenElements != '') {
    	$('#footer-nav').prepend('<ul class="no-children">' + noChildrenElements + '</ul>');
    }
 
});
