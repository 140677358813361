jQuery(document).ready(function($) {

	const $headerMain = $('#header-main');
	const $headerTop = $('#header-top');

	function stickyHeaderCheck() {
		let scrollPosition = $(document).scrollTop();
		let headerMainPosition = $headerMain.position().top;
		let headerTopBottomPosition = $headerTop.position().top + $headerTop.height();

		if(!$headerMain.hasClass('--sticky') && scrollPosition > headerMainPosition) {
			$headerMain.addClass('--sticky');
		} else if($headerMain.hasClass('--sticky') && scrollPosition < headerTopBottomPosition) {
			$headerMain.removeClass('--sticky');
		}
	}

	stickyHeaderCheck();

	$(window).scroll(function() {
		stickyHeaderCheck();
	});

	$(window).resize(function() {
		stickyHeaderCheck();
	});

});